import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { withTranslation } from 'react-i18next';
import './styles/404.scss';
import { useEffect } from "react";
import NotFoundPage from "../components/404-component/404-component";

const isBrowser = () => typeof localStorage !== "undefined";
const languageCode = isBrowser() ? localStorage.getItem('language_code') : 'de';

const NotFound = ({ t, i18n }) => {

  useEffect(() => {
    i18n.changeLanguage(languageCode);
  }, []);

  return (
    <Layout isShowAction={false} minHeight="100vh">
      <Seo title="Not found" />
      <NotFoundPage
        t={t}
        i18n={i18n}
      />
    </Layout>
  )
};

export default withTranslation('blog')(NotFound);
